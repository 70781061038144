import djangoRequest from '@/util/django-request';

const PATH = '/pos';

export const getPosBySentence = (listQuery: any) => djangoRequest({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
  },
});
