




































































































import { deleteWordDictionary, getWordDictionaryList } from '@/api/word-dictionary';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import WordForm from './WordForm.vue';

@Component({
  name: 'LearningIndex',
  components: {
    Pagination,
    WordForm,
  },
})

export default class extends Vue {
  @Prop({ required: true }) private type!: string;

  mounted() {
    this.getWordDictionaryList();
  }

  private loading = true;

  private formVisible = false;

  private uid = '';

  private listQuery = {
    tag: 'NNG',
    type: 'word',
    value: '',
    page: 1,
    size: 10,
    wordType: this.type,
  }

  private totalElements = 0;

  private tableData = [];

  private getWordDictionaryList() {
    this.loading = true;
    getWordDictionaryList(this.listQuery).then((res) => {
      this.loading = false;
      this.tableData = res.data.content;
      this.totalElements = res.data.totalElements;
    });
  }

  private handleChangePage(pageable: any) {
    this.listQuery.page = pageable.page;
    this.getWordDictionaryList();
  }

  private handleChangeType() {
    this.listQuery.page = 1;
    this.getWordDictionaryList();
  }

  private handleDelete(item: any) {
    this.$confirm(`정말 ${item.word}을(를) 삭제하시겠습니까?`, {
      confirmButtonText: '예',
      cancelButtonText: '아니오',
    }).then(() => {
      deleteWordDictionary(item.uid).then(() => {
        this.$message.success('성공적으로 단어가 삭제되었습니다.');
        if (this.tableData.length === 1 && this.listQuery.page > 1) this.listQuery.page -= 1;
        this.getWordDictionaryList();
      }).catch(() => {
        this.$message.error('단어를 삭제하는데 실패했습니다.');
      });
    });
  }

  private handleVisibleForm(uid: string) {
    this.uid = uid;
    this.formVisible = !this.formVisible;
  }

  private handleSuccessSave(isAdd: boolean) {
    this.handleVisibleForm('');
    if (isAdd) this.listQuery.page = 1;
    this.getWordDictionaryList();
  }

  private handleSearch() {
    this.listQuery.page = 1;
    this.getWordDictionaryList();
  }
}
