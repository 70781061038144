





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { addPosWord, getPosWord, updatePosWord } from '@/api/pos-dictionary';

@Component({
  name: 'PosWordForm',
})

export default class extends Vue {
  @Prop({ required: true }) private selectedPosUid!: string|null;

  private posAddVisible = false;

  private loading = false;

  private formData = {
    word: '',
    tag: '',
  };

  mounted() {
    if (this.selectedPosUid) this.getPosWord();
  }

  private getPosWord() {
    this.loading = true;
    getPosWord(this.selectedPosUid).then((res) => {
      this.formData = res.data;
      this.loading = false;
    });
  }

  private addPosWord() {
    addPosWord(this.formData).then(() => {
      this.$message({
        message: '정상적으로 추가되었습니다.',
        type: 'success',
      });
      this.$emit('getPosDictionaryList');
      this.$emit('handlePosForm');
    }).catch((err) => {
      this.$message({
        type: 'warning',
        message: err.response.data.message,
      });
    });
  }

  private updatePosWord() {
    updatePosWord(this.formData).then(() => {
      this.$message({
        message: '정상적으로 수정되었습니다.',
        type: 'success',
      });
      this.$emit('getPosDictionaryList');
      this.$emit('handlePosForm');
    }).catch((err) => {
      this.$message({
        type: 'warning',
        message: err.response.data.message,
      });
    });
  }
}
