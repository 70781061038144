import request from '@/util/request';

const PATH = '/pos-dictionary';

export const getPosDictionaryList = () => request({
  url: PATH,
  method: 'get',
});

export const getPosDictionaryByWord = (word: string) => request({
  url: `${PATH}/word/${word}`,
  method: 'get',
});

export const addPosWord = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const savePosWordByNewsWord = (data: any) => request({
  url: `${PATH}/news-word`,
  method: 'post',
  data,
});

export const updatePosWord = (data: any) => request({
  url: `${PATH}/${data.uid}`,
  method: 'put',
  data,
});

export const getPosWord = (uid: string|null) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const deletePosWord = (uid: string|null) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});
