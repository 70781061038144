import request from '@/util/request';

const PATH = '/word-dictionary';

export const getWordDictionaryList = (listQuery: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});

export const getWordDictionaryListAll = () => request({
  url: `${PATH}/all`,
  method: 'get',
});

export const getWordDictionary = (uid: any) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const addWordDictionary = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const updateWordDictionary = (uid: string, data: any) => request({
  url: `${PATH}/${uid}`,
  method: 'put',
  data,
});

export const deleteWordDictionary = (uid: any) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});

export const isUsableWord = (word: any) => request({
  url: `${PATH}/word-check/${word}`,
  method: 'get',
});
