



































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getPosDictionaryList, deletePosWord, addPosWord } from '@/api/pos-dictionary';
import { ElForm } from 'element-ui/types/form';
import { addWordDictionary, getWordDictionary, updateWordDictionary } from '@/api/word-dictionary';
import { getPosBySentence } from '@/api/pos';
import PosWordForm from './PosWordForm.vue';

@Component({
  name: 'WordDictionaryForm',
  components: {
    PosWordForm,
  },
})

export default class extends Vue {
  @Prop({ required: true }) private uid!: string;

  @Prop({ required: true }) private type!: string;

  mounted() {
    this.setForm();
  }

  private loading = true;

  private posDictionaries: any = [];

  private posAddVisible = false;

  private selectedPosUid: string|null = null;

  private posAnalysisVisible = false;

  private formData: any = {
    word: '',
    tag: 'NNG',
    posDictionaries: [],
    type: this.type,
  };

  private analysisForm = {
    sentence: '',
    posList: [],
  }

  private rules = {
    word: [
      { required: true, message: '단어를 입력하세요.', trigger: 'blur' },
    ],
    tag: [
      { required: true, message: '품사를 선택하세요.', trigger: 'blur' },
    ],
  };

  private tagList = [
    { label: '일반명사', value: 'NNG' },
    { label: '동사', value: 'VV' },
    { label: '고유명사(지역)', value: 'NNP' },
  ];

  private modifyPos(uid: string) {
    this.selectedPosUid = uid;
    this.handlePosForm();
  }

  private deletePos(uid: string) {
    this.$confirm('정말 삭제하시겠습니까?', '경고', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }).then(() => {
      deletePosWord(uid).then(() => {
        this.getPosDictionaryList(this.formData.posDictionaries);
        this.$message({
          type: 'success',
          message: '정상적으로 삭제되었습니다.',
        });
      });
    }).catch(() => {
      this.$message({
        type: 'error',
        message: '삭제하는데 실패하였습니다.',
      });
    });
  }

  private handlePosForm() {
    this.posAddVisible = !this.posAddVisible;
    if (!this.posAddVisible) this.selectedPosUid = null;
  }

  private getPosDictionaryList(posDictionaries: any) {
    getPosDictionaryList().then((res) => {
      this.loading = false;
      this.posDictionaries = this.getTransferData(posDictionaries, res.data);
    }).catch(() => {
      this.$message.error('형태소 목록을 가져오는데 실패했습니다.');
      this.$emit('close');
    });
  }

  private setForm() {
    if (this.uid) {
      getWordDictionary(this.uid).then((res) => {
        this.formData = {
          ...res.data,
        };
        this.getPosDictionaryList(res.data.posDictionaries);
      });
    } else {
      this.getPosDictionaryList([]);
    }
  }

  private handleClose() {
    this.$emit('close');
  }

  private handleSave() {
    (this.$refs.wordForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        if (this.uid) {
          updateWordDictionary(this.uid, this.formData).then(() => {
            this.$message.success('성공적으로 단어를 수정했습니다.');
            this.$emit('save', false);
          });
        } else {
          addWordDictionary(this.formData).then(() => {
            this.$message.success('성공적으로 단어를 추가했습니다.');
            this.$emit('save', true);
          });
        }
      }
    });
  }

  private getTransferData(choicePosDictionaries: any, posDictionaries: any) {
    const transferData: any[] = [];
    posDictionaries.forEach((pos: any) => {
      transferData.push({
        key: pos.uid,
        label: pos.word,
        disabled: (pos.wdUid !== null && choicePosDictionaries.indexOf(pos.uid) === -1),
        tag: pos.tag,
      });
    });
    return transferData;
  }

  private handleAnalysisSentence() {
    getPosBySentence(this.analysisForm).then((res) => {
      this.analysisForm.posList = res.data;
    }).catch(() => {
      this.$message.error('형태소 분석을 실패했습니다.');
    });
  }

  private existPos(pos: any) {
    const index = this.posDictionaries.findIndex((posDictionary: any) => posDictionary.label === pos[0]);
    return index > -1;
  }

  private handleAddPos(pos: any) {
    this.$confirm(`정말 ${pos[0]}을(를) 형태소로 추가하시겠습니까?`, {
      type: 'info',
      confirmButtonText: '예',
      cancelButtonText: '아니오',
    }).then(() => {
      const posData = {
        word: pos[0],
        tag: pos[1],
      };
      addPosWord(posData).then((res) => {
        this.formData.posDictionaries.push(res.data.uid);
        this.posDictionaries.push({
          key: res.data.uid,
          label: res.data.word,
          disabled: false,
          tag: res.data.tag,
        });
      }).catch(() => {
        this.$message.error('형태소를 추가하는데 실패했습니다.');
      });
    });
  }
}
